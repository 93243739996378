// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-astra-2021-js": () => import("./../../../src/pages/astra2021.js" /* webpackChunkName: "component---src-pages-astra-2021-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projectsnpublications-js": () => import("./../../../src/pages/projectsnpublications.js" /* webpackChunkName: "component---src-pages-projectsnpublications-js" */),
  "component---src-pages-teams-index-js": () => import("./../../../src/pages/teams/index.js" /* webpackChunkName: "component---src-pages-teams-index-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/eventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-feedback-template-js": () => import("./../../../src/templates/feedbackTemplate.js" /* webpackChunkName: "component---src-templates-feedback-template-js" */),
  "component---src-templates-register-template-js": () => import("./../../../src/templates/registerTemplate.js" /* webpackChunkName: "component---src-templates-register-template-js" */),
  "component---src-templates-team-template-js": () => import("./../../../src/templates/teamTemplate.js" /* webpackChunkName: "component---src-templates-team-template-js" */)
}

